<template>
    <div class="content" v-loading="loading">
        <h3>Edit project: {{project.name}}</h3>
        <div>
            <el-row>
                <el-col :span="24">
                    <el-form ref="ruleForm" :rules="rules" :model="project" label-width="120px">
                        <el-form-item label="Project name*" prop="name">
                            <el-input v-model="project.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Description*" prop="desc">
                            <el-input type="textarea" :rows="15" v-model="project.description"></el-input>
                        </el-form-item>
                        <el-form-item label="Tags">
                            <el-tag :key="tag" v-for="tag in project.tags" closable :disable-transitions="false" @close="handleClose(tag)">
                                {{tag}}
                            </el-tag>
                            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="mini" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                            </el-input>
                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>

                        </el-form-item>
                        <el-form-item label="Category*" prop="category">
                            <span v-show="!categories || categories.length == 0">Loading categories...</span>
                            <el-select v-model="project.category" placeholder="Select" v-loading="!categories" v-show="categories && categories.length > 0">
                                <el-option v-for="item in categories" :key="item.value" :label="item.label" :value="item._id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Project documentation">
                            <el-input v-model="project.website" placeholder="http://..."></el-input>
                        </el-form-item>

                    </el-form>
                </el-col>
                <el-col :span="24">
                    <el-card style="margin-left: 24px">

                        <div slot="header">
                            <strong>Thumbnail*</strong>
                            <span style="float: right"> PNG or JPEG, 72dpi, 512x512</span>

                        </div>

                        <ImagePicker uploadFolder="library/projects" :src="project.thumbnail" :width="512" :height="512" v-on:image="avatarSelected" />
                    </el-card>
                    <el-card style="margin-left: 24px; margin-top: 24px">
                        <div slot="header">
                            <strong>Detail photos *</strong>
                            <span style="float: right"> PNG or JPEG, 72dpi, 1024x1024</span>
                        </div>
                        <strong>One photo required</strong>, but you can load up to 4 photos,
                        <div style="clear: both;">
                            <div style="margin-bottom: 12px;">
                                <ImagePicker style="float: left; margin-right: 12px;" uploadFolder="library/projects" v-for="idx in 4" :key="idx" :src="project.photos && idx in project.photos && project.photos[idx].url || ''" :index="idx" :width="1024" :height="1024" v-on:image="thumbnailSelected" />
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </el-card>
                    <el-card style="margin-left: 24px; margin-top: 24px">
                        <div slot="header">
                            <strong>Downloads*</strong>
                            <span style="float: right"> Max 2MB</span>
                            <FilePicker v-on:fileSelected="fileSelected" v-on:fileUploading="fileUploading" v-on:fileChanged="fileChanged" :initialFiles="initialFiles" />
                        </div>

                    </el-card>
                </el-col>
            </el-row>
            <div style="float: right;margin-top: 24px">
                <el-form :model="project" style="text-align: right">
                    <el-form-item>
                        <el-button type="success" :disabled="!submitEnabled" @click="submitForm('ruleForm')">Save</el-button>
                        <el-button @click="resetForm('ruleForm')">Cancel</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

    </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import moment from 'moment'
import ImagePicker from './ImagePicker.vue'
import FilePicker from './FilePicker.vue'
let db, projectRef, categoriesRef

export default {
    components: {
        ImagePicker,
        FilePicker
    },
    data() {
        return {
            loading: false,
            project: {},
            inputValue: '',
            inputVisible: false,

            categories: [],

            filesUploading: false,
            rules: {
                name: [
                    { required: true, message: 'Please input Project name', trigger: 'blur' },
                    { min: 3, max: 255, message: 'Length should be 3 to 255', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: 'Please input Description', trigger: 'blur' },
                    // { min: 50, max: 1000, message: 'Length should be 50 to 1000 characters', trigger: 'blur' }
                ],
                category: [
                    { required: true, message: 'Please select a category', trigger: 'blur' }
                ]
            },
        }
    },
    created() {
        db = firebase.database().ref()
    },
    mounted() {
        const _this = this
        projectRef = db.child('/library/projects/' + this.$route.params.id)
        this.$bindAsObject('project', projectRef, null, function(data) {
            _this.loading = false
            _this.$nextTick(function() {
                const userId = _this.project.userId || null
                if (userId == null) {
                    _this.$router.push('/not-found')
                }
                _this.project.tags = _this.project.tags || []
            })
        })
        categoriesRef = db.child('/library/categories')
        this.$bindAsArray('categories', categoriesRef, null, function() {
            console.log('categories loaded')
        })
    },
    computed: {
        currentUserId() {
            const user = firebase.auth().currentUser
            if (user) return user.uid
            return 'na'
        },
        prettyDate() {
            if (this.project) return moment(this.project.created).format('MMMM DD YYYY')
            return ''
        },
        projectKey() {
            return (this.project['.key'] || null)
        },
        initialFiles() {
            return (this.project && this.project.files || [])
        },
        submitEnabled: function() {
            return (this.project.name != '' && this.project.desc != '' && this.project.thumbnail != '')
        },
    },
    methods: {
        mapFiles(files) {
            const items = files.map(function(file, idx) {
                if (file.response && file.response.metadata) {

                    const metadata = file.response.metadata
                    return {
                        bucket: metadata.bucket,
                        fullPath: metadata.name,
                        name: file.name,
                        url: metadata.mediaLink,
                        size: metadata.size,
                        contentType: metadata.contentType
                    }
                } else {
                    return file
                }
            })
            return items
        },
        fileSelected(files) {
            console.log(files)
            this.project.files = this.mapFiles(files)
            this.filesUploading = false
        },
        fileChanged(files) {
            console.log(files)

            this.project.files = this.mapFiles(files)
        },
        fileUploading(files) {
            this.filesUploading = true
        },

        avatarSelected(image) {
            // alert('yay')
            if (image)
                this.project.thumbnail = image.url
            else
                this.project.thumbnail = null
            // this.imageRef = image
        },
        thumbnailSelected(image, key) {
            debugger
            if (image) {
                this.project.photos[key] = image
            } else {
                if (key in this.project.photos) {
                    this.project.photos.splice(this.project.photos.indexOf(key), 1)
                    this.$forceUpdate()
                }
            }
        },

        handleClose(tag) {

            this.project.tags.splice(this.project.tags.indexOf(tag), 1);
            this.$forceUpdate()
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.project.tags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$router.push('/project/' + this.projectKey)
        },
        submitForm(formName) {
            const _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.loading = true;
                    const auth = firebase.auth();

                    const projectData = Object.assign({}, _this.project)
                    delete projectData['.key']
                    const db = firebase.database().ref()
                    const updates = {}
                    updates['/library/projects/' + _this.projectKey] = projectData
                    updates['/library/profiles/' + _this.project.userId + '/projects/' + _this.projectKey] = projectData
                    updates['/library/recent/' + _this.projectKey] = projectData
                    db.update(updates)
                        .then(function(res) {
                            // alert('Success')
                            _this.loading = false;

                            _this.$message('Project updated')
                            _this.$router.push('/project/' + _this.projectKey)
                            // console.log(res)
                        }).catch(function(error) {
                            // alert('Error')
                            _this.loading = false;

                            _this.$message('Error updating project, please try again')
                            console.log(error)
                        })
                }
            })
        }
    }
}
</script>
<style scoped>
.thumbnail {
    max-width: 250px;
}

.el-tag+.el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}
</style>

